/*!
 KeyTable 2.1.1
 ©2009-2016 SpryMedia Ltd - datatables.net/license
*/
(function(f){"function"===typeof define&&define.amd?define(["jquery","datatables.net"],function(h){return f(h,window,document)}):"object"===typeof exports?module.exports=function(h,i){h||(h=window);if(!i||!i.fn.dataTable)i=require("datatables.net")(h,i).$;return f(i,h,h.document)}:f(jQuery,window,document)})(function(f,h,i,m){var g=f.fn.dataTable,k=function(a,b){if(!g.versionCheck||!g.versionCheck("1.10.8"))throw"KeyTable requires DataTables 1.10.8 or newer";this.c=f.extend(!0,{},g.defaults.keyTable,
k.defaults,b);this.s={dt:new g.Api(a),enable:!0,focusDraw:!1};this.dom={};var d=this.s.dt.settings()[0],c=d.keytable;if(c)return c;d.keytable=this;this._constructor()};f.extend(k.prototype,{blur:function(){this._blur()},enable:function(a){this.s.enable=a},focus:function(a,b){this._focus(this.s.dt.cell(a,b))},focused:function(a){if(!this.s.lastFocus)return!1;var b=this.s.lastFocus.index();return a.row===b.row&&a.column===b.column},_constructor:function(){this._tabInput();var a=this,b=this.s.dt,d=f(b.table().node());
"static"===d.css("position")&&d.css("position","relative");f(b.table().body()).on("click.keyTable","th, td",function(){if(!1!==a.s.enable){var c=b.cell(this);c.any()&&a._focus(c)}});f(i).on("keydown.keyTable",function(b){a._key(b)});if(this.c.blurable)f(i).on("click.keyTable",function(c){f(c.target).parents(".dataTables_filter").length&&a._blur();f(c.target).parents().filter(b.table().container()).length||f(c.target).parents("div.DTE").length||a._blur()});if(this.c.editor)b.on("key.keyTable",function(b,
c,d,f,i){a._editor(d,i)});if(b.settings()[0].oFeatures.bStateSave)b.on("stateSaveParams.keyTable",function(b,c,d){d.keyTable=a.s.lastFocus?a.s.lastFocus.index():null});b.on("xhr.keyTable",function(){if(!a.s.focusDraw){var c=a.s.lastFocus;c&&(a.s.lastFocus=null,b.one("draw",function(){a._focus(c)}))}});b.on("destroy.keyTable",function(){b.off(".keyTable");f(b.table().body()).off("click.keyTable","th, td");f(i.body).off("keydown.keyTable").off("click.keyTable")});var c=b.state.loaded();if(c&&c.keyTable)b.one("init",
function(){var a=b.cell(c.keyTable);a.any()&&a.focus()});else this.c.focus&&b.cell(this.c.focus).focus()},_blur:function(){if(this.s.enable&&this.s.lastFocus){var a=this.s.lastFocus;f(a.node()).removeClass(this.c.className);this.s.lastFocus=null;this._emitEvent("key-blur",[this.s.dt,a])}},_columns:function(){var a=this.s.dt,b=a.columns(this.c.columns).indexes(),d=[];a.columns(":visible").every(function(a){-1!==b.indexOf(a)&&d.push(a)});return d},_editor:function(a,b){var d=this.s.dt,c=this.c.editor;
b.stopPropagation();c.inline(this.s.lastFocus.index());var e=f("div.DTE input, div.DTE textarea");e.length&&e[0].select();d.keys.enable("navigation-only");d.one("key-blur.editor",function(){c.displayed()&&c.submit()});c.one("close",function(){d.keys.enable(!0);d.off("key-blur.editor")})},_emitEvent:function(a,b){this.s.dt.iterator("table",function(d){f(d.nTable).triggerHandler(a,b)})},_focus:function(a,b){var d=this,c=this.s.dt,e=c.page.info(),l=this.s.lastFocus;if(this.s.enable){if("number"!==typeof a){var j=
a.index(),b=j.column,a=c.rows({filter:"applied",order:"applied"}).indexes().indexOf(j.row);e.serverSide&&(a+=e.start)}if(-1!==e.length&&(a<e.start||a>=e.start+e.length))this.s.focusDraw=!0,c.one("draw",function(){d.s.focusDraw=!1;d._focus(a,b)}).page(Math.floor(a/e.length)).draw(!1);else if(-1!==f.inArray(b,this._columns())){e.serverSide&&(a-=e.start);e=c.cell(":eq("+a+")",b,{search:"applied"});if(l){if(l.node()===e.node())return;this._blur()}l=f(e.node());l.addClass(this.c.className);this._scroll(f(h),
f(i.body),l,"offset");j=c.table().body().parentNode;j!==c.table().header().parentNode&&(j=f(j.parentNode),this._scroll(j,j,l,"position"));this.s.lastFocus=e;this._emitEvent("key-focus",[this.s.dt,e]);c.state.save()}}},_key:function(a){if(this.s.enable&&!(0===a.keyCode||a.ctrlKey||a.metaKey||a.altKey)){var b=this.s.lastFocus;if(b){var d=this,c=this.s.dt;if(!(this.c.keys&&-1===f.inArray(a.keyCode,this.c.keys)))switch(a.keyCode){case 9:this._shift(a,a.shiftKey?"left":"right",!0);break;case 27:this.s.blurable&&
!0===this.s.enable&&this._blur();break;case 33:case 34:a.preventDefault();var e=c.cells({page:"current"}).nodes().indexOf(b.node());c.one("draw",function(){var a=c.cells({page:"current"}).nodes();d._focus(c.cell(e<a.length?a[e]:a[a.length-1]))}).page(33===a.keyCode?"previous":"next").draw(!1);break;case 35:case 36:a.preventDefault();b=c.cells({page:"current"}).indexes();this._focus(c.cell(b[35===a.keyCode?b.length-1:0]));break;case 37:this._shift(a,"left");break;case 38:this._shift(a,"up");break;
case 39:this._shift(a,"right");break;case 40:this._shift(a,"down");break;default:!0===this.s.enable&&this._emitEvent("key",[c,a.keyCode,this.s.lastFocus,a])}}}},_scroll:function(a,b,d,c){var c=d[c](),e=d.outerHeight(),d=d.outerWidth(),f=b.scrollTop(),j=b.scrollLeft(),i=a.height(),a=a.width();c.top<f&&b.scrollTop(c.top);c.left<j&&b.scrollLeft(c.left);c.top+e>f+i&&b.scrollTop(c.top+e-i);c.left+d>j+a&&b.scrollLeft(c.left+d-a)},_shift:function(a,b,d){var c=this.s.dt,e=c.page.info(),i=e.recordsDisplay,
j=this.s.lastFocus,g=this._columns();if(j){var h=c.rows({filter:"applied",order:"applied"}).indexes().indexOf(j.index().row);e.serverSide&&(h+=e.start);c=c.columns(g).indexes().indexOf(j.index().column);e=g[c];"right"===b?c>=g.length-1?(h++,e=g[0]):e=g[c+1]:"left"===b?0===c?(h--,e=g[g.length-1]):e=g[c-1]:"up"===b?h--:"down"===b&&h++;0<=h&&h<i&&-1!==f.inArray(e,g)?(a.preventDefault(),this._focus(h,e)):!d||!this.c.blurable?a.preventDefault():this._blur()}},_tabInput:function(){var a=this,b=this.s.dt,
d=null!==this.c.tabIndex?this.c.tabIndex:b.settings()[0].iTabIndex;if(-1!=d)f('<div><input type="text" tabindex="'+d+'"/></div>').css({position:"absolute",height:1,width:0,overflow:"hidden"}).insertBefore(b.table().node()).children().on("focus",function(){a._focus(b.cell(":eq(0)","0:visible",{page:"current"}))})}});k.defaults={blurable:!0,className:"focus",columns:"",editor:null,focus:null,keys:null,tabIndex:null};k.version="2.1.1";f.fn.dataTable.KeyTable=k;f.fn.DataTable.KeyTable=k;g.Api.register("cell.blur()",
function(){return this.iterator("table",function(a){a.keytable&&a.keytable.blur()})});g.Api.register("cell().focus()",function(){return this.iterator("cell",function(a,b,d){a.keytable&&a.keytable.focus(b,d)})});g.Api.register("keys.disable()",function(){return this.iterator("table",function(a){a.keytable&&a.keytable.enable(!1)})});g.Api.register("keys.enable()",function(a){return this.iterator("table",function(b){b.keytable&&b.keytable.enable(a===m?!0:a)})});g.ext.selector.cell.push(function(a,b,
d){var b=b.focused,a=a.keytable,c=[];if(!a||b===m)return d;for(var e=0,f=d.length;e<f;e++)(!0===b&&a.focused(d[e])||!1===b&&!a.focused(d[e]))&&c.push(d[e]);return c});f(i).on("preInit.dt.dtk",function(a,b){if("dt"===a.namespace){var d=b.oInit.keys,c=g.defaults.keys;if(d||c)c=f.extend({},d,c),!1!==d&&new k(b,c)}});return k});
